import React, {Component, useEffect} from "react";
import Layout from '../components/layout'

const NotFoundPage = () => {
  return (

    <Layout pageTitle="Home Page">
<div className="container">
	<div className="row">
		<div className="col-md-12">
			
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-md-9">
						<div className="user-content text-center block-padding-medium">
							<h1>404 - not found</h1>
							<p>Sorry - we couldn't find that one!</p>
						</div>
					</div>
				</div>
			</div>
      </div>
				</div>
			</div>
    </Layout>
  ) 
}
export default NotFoundPage